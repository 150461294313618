import { createContext, useState, useEffect } from 'react';


//import { utilMethods } from '../util/utilMethods';
import { firestoreMethods } from './firestoreMethods';

export const firestoreAudits = createContext();

const AuditProvider = (props) => {

  const [audit, setAudit] = useState();
  //const [auditSteps, setAuditSteps] = useState();

  const [refreshAudit, setRefreshAudit] = useState(false);
  const [refreshAuditSteps, setRefreshAuditSteps] = useState(true);

  
  const [viewAudits, setViewAudits] = useState(null);
  const [viewAllSteps, setViewAllSteps] = useState(null);

  const [refreshViewAudits, setRefreshViewAudits] = useState(true);

  const [activeSlices, setActiveSlices] = useState([]);

  //============= bakery
  const [bakeryStepDefs, setBakeryStepDefs] = useState(null);
  const [bakerySteps, setBakerySteps] = useState(null);

  const isAdmin = window.location.pathname.toLowerCase().startsWith('/admin');
  const isBaker = window.location.pathname.toLowerCase().startsWith('/baker');

  //const location = useLocation();
  //console.log('LOCATION2: ', location.pathname === '/admin')

  // refresh ALL AUDITS
  useEffect(() => {

    const fetchData = async () => {
      //console.log("UE: refresh ALL AUDITS")
      
      const filter = {name: 'sliceID', op: 'in', value: activeSlices}

      const newData = await firestoreMethods.get("audits", filter);
      setViewAudits(newData);

      // GET ALL STEPS
      // TODO: turning off for performance for now
      //const stepData = await firestoreMethods.get("asteps");
      //setViewAllSteps(stepData);
      setViewAllSteps({});

      setRefreshViewAudits(false);
      
      //console.log("AuditProvider: viewAudits: ", newData);
    };

    if (isAdmin && refreshViewAudits && activeSlices?.length > 0) fetchData();
  }, [isAdmin, refreshViewAudits, activeSlices]); // Trigger when refreshViewAudits changes

  // getSlices
  useEffect(() => {

    const fetchData = async () => {
      //console.log("UE: get slices")
      
      /*
      const slices = ['9', '510', '511', '512', '513', '514', 
                  '515', '516', '517', '518', '519', '520', '521', '522',
                  '523', '524', '525', '526', '527', '528']; // Array of slice IDs
    */
      //const sliceFilter = {name: 'sliceID', op: 'in', value: slices}
      const slicesObj = await firestoreMethods.getD("bakes", 'activeSlices');
      setActiveSlices(slicesObj?.slices);
    };
    if (isAdmin) fetchData();
  }, [isAdmin]); 

  // refresh single audit
  useEffect(() => {

    const fetchData = async () => {
      //console.log("UE: refresh AUDIT")

      const newData = await firestoreMethods.getD("audits", audit.id);
      setAudit(newData);

      if (!isBaker) {

        // update this audit in viewAudits (without refetching)
        const objectIndex = viewAudits.findIndex((a) => a.id === audit.id);
        // Check if object is found
        if (objectIndex !== -1) {
          // Create a copy of the array
          const newArray = [...viewAudits];

          // Replace the object at the index with the updated object
          newArray[objectIndex] = newData;

          // Update the state with the new array
          setViewAudits(newArray);

        } else {
          console.error('Object not found in the array');
        }
      }

      setRefreshAudit(false);
    };

    if ((isAdmin || isBaker) && refreshAudit) fetchData();
  }, [isAdmin, isBaker, refreshAudit, viewAudits, audit?.id]);

  // ======================== IS BAKER ========================
  // ======================== IS BAKER ========================
  // ======================== IS BAKER ========================
  useEffect(() => {

    const fetchData = async () => {
      //console.log("UE: is baker or admin")
      //TODO later optimize and retrieve only bakery stepsconst filter = {name: 'id', op: '>=', value: 'BAKER'}
      const btypesData = await firestoreMethods.get("bakery");
      //console.log('BAKER', btypesData);
      //const btypes = btypesData.filter(bt => bt.id.startsWith('BAKER'));
      //setBakeryStepDefs(btypes);
      setBakeryStepDefs(btypesData);
    };

    if (isBaker || isAdmin) fetchData();
  }, [isBaker, isAdmin]);

  useEffect(() => {

    const fetchData = async () => {
      //console.log("UE: is baker")
      
      const bstepsFilter = {name: 'baker', op: '==', value: true}
      const b2stepsFilter = {name: 'bakerStepActive', op: '==', value: true}
      const bstepsData = await firestoreMethods.get("asteps", bstepsFilter, b2stepsFilter);
      //console.log('BSTEPS', bstepsData);
      setBakerySteps(bstepsData);
    };

    if (isBaker) fetchData();
  }, [isBaker]);

  // ======================== UPDATE STEPS ========================
  const updateBakerySteps = async (updateId, updateStep) => {
    const objectIndex = bakerySteps.findIndex((s) => s.id === updateId);
    // Check if object is found
    if (objectIndex !== -1) {
      const newArray = [...bakerySteps];       // Create a copy of the array
      newArray[objectIndex] = updateStep;       // Replace the object at the index with the updated object
      setBakerySteps(newArray);       // Update the state with the new array
    } else {
      console.error('Object not found in the array');
    }
  }

  // ======================== RETURN ========================
  // ======================== RETURN ========================
  // ======================== RETURN ========================


  return (
    <firestoreAudits.Provider
    value={{
      activeSlices,
      setActiveSlices,
      viewAudits,
      setViewAudits,
      viewAllSteps,
      setRefreshViewAudits,
      refreshAuditSteps,
      setRefreshAuditSteps,
      audit, 
      setAudit,
      setRefreshAudit,
      bakeryStepDefs,
      bakerySteps,
      updateBakerySteps,
    }}>
      {props.children}
    </firestoreAudits.Provider>
    
  );
};

export default AuditProvider;


